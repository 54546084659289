
import { BreachRatioItem } from '@/store/modules/company-tabs/breach-ratio';
import { ChTimeItem } from '@/utilities';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class BreachRatioWidget extends Vue {
  @Prop() data!: BreachRatioItem;

  get isPositive() {
    return this.data.Ratio >= 0;
  }

  displayDate(date: ChTimeItem): string {
    const monthStr = new Date(date.timestamp)
      .toLocaleString('en-us', { month: 'short', timeZone: 'UTC' })
      .toLocaleUpperCase();
    return monthStr + ' ' + String(date.year);
  }
}
