
import { Component, Vue, Prop } from 'vue-property-decorator';
import EarlyWarningTimelineChart from './EarlyWarningTimelineChart/EarlyWarningTimelineChart.vue';
import EarlyWarningTable from './EarlyWarningTable.vue';
import { EarlyWarningItem, EarlyWarningModule } from '@/store/modules/company-tabs/early-warning';
import CollapseContent from './CollapseContent.vue';
import Tachometer from '@/components/Tachometer.vue';
import { AttackStageItem, AttackStageModule } from '@/store/modules/company-tabs/attack-stage';

@Component({
  components: {
    EarlyWarningTimelineChart,
    CollapseContent,
    EarlyWarningTable,
    Tachometer,
  },
})
export default class EarlyWarningDashboard extends Vue {
  @Prop() year!: number;
  @Prop({ default: false }) isCurrent!: boolean;
  public peerGroup: EarlyWarningItem['PeerGroup'] = 'industry';
  public hoveredLabel: string = '';
  public stage: keyof AttackStageItem = 'StageOver12Months';

  get peerGroupOptions(): { label: string; value: EarlyWarningItem['PeerGroup'] }[] {
    return [
      {
        label: `Direct peer group (${EarlyWarningModule.industryMap[this.year].length})`,
        value: 'industry',
      },
      {
        label: `Large peer group (${EarlyWarningModule.sectorMap[this.year].length})`,
        value: 'sector',
      },
    ];
  }

  get stageOptions(): { label: string; value: keyof AttackStageItem }[] {
    return [
      {
        label: 'past 12 month',
        value: 'StageOver12Months',
      },
      {
        label: 'past 6 month',
        value: 'StageOver6Months',
      },
      {
        label: 'past 3 month',
        value: 'StageOver3Months',
      },
    ];
  }

  get value() {
    return AttackStageModule.stageItem ? AttackStageModule.stageItem[this.stage] : 0;
  }

  get stageValue() {
    return this.value * 2 - 1;
  }

  get colorRules() {
    const value = AttackStageModule.stageItem ? AttackStageModule.stageItem[this.stage] : 0;
    const colors = ['#52B41D', '#EECC16', '#F7931E', '#E85133'];
    return colors.map((_color, ind) => (ind < value ? colors[value - 1] : '#E4E4E4'));
  }

  setHoveredLabel(dateString: string) {
    this.hoveredLabel = dateString;
  }

  reduce(item: { label: string; value: EarlyWarningItem['PeerGroup'] }) {
    return item.value;
  }

  reduceStage(item: { label: string; value: keyof AttackStageItem }) {
    return item.value;
  }
}
